/* eslint-disable react/react-in-jsx-scope */
import {
  Fragment,
  h
} from 'preact';
import {
  useContext,
  useEffect,
  useState
} from 'preact/hooks';
import { route } from 'preact-router';
import { AdminClientContext } from '../AdminClientContext';
import { Toast } from '../../../../../../lib/components/toast';

import './styles.scss';
import '../../../styles.scss';

export const AdminSignupToken = ({ token }: { token?: string; path?: string }): JSX.Element => {
  const client = useContext(AdminClientContext);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('There was an error verifying your invitation.');
  const [formToken, setFormToken] = useState<string>('');

  useEffect(() => {
    if (token) {
      void doLoad(token);
    }
  }, []);

  const doLoad = async (signupToken: string) => {
    try {
      const loader = document.getElementById('loaderWrapper');
      loader.style.display = 'flex';
      const validationData = await client.validateAccountToken(signupToken);
      client.contact = {
        name: validationData.name,
        email: validationData.email
      };
      client.token = signupToken;
      route('/create-credentials');
    } catch (e) {
      setErrorMessage((e as Error).message);
      setError(true);
    }
  };

  const processToken = async () => {
    await doLoad(formToken);
  };

  if (token) {
    return (
      <Fragment>
        {!error && (
          <div id="loaderWrapper">
            <div className="loaderContainer">
              <div className='loader' />
            </div>
          </div>
        )}

        {error && (
          <Toast
            error={true}
            message={errorMessage}
            closeFunc={async () => Promise.resolve()}
          />
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <main id="signupTokenInput">
        <div id="signupTokenInputCard">
          <h1>Enter a token</h1>
          <input
            className="centered"
            type="text"
            onChange={(e) => {
              setFormToken(e.currentTarget.value);
            }}
          />
          <br />
          <button className="primary" onClick={processToken} disabled={!formToken}>Submit</button>
        </div>
      </main>

      {error && (
        <Toast
          error={true}
          message={errorMessage}
          closeFunc={async () => Promise.resolve()}
        />
      )}
    </Fragment>
  );
};
