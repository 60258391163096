/* eslint-disable react/react-in-jsx-scope */
import {
  Fragment,
  h
} from 'preact';
import {
  useContext,
  useEffect,
  useState
} from 'preact/hooks';
import { useDlocs } from '../../adminData';
import { Toast } from '../../../../../../lib/components/toast';
import { AdminClientContext } from '../../components/AdminClientContext';
import {
  dloc as dlocType,
  formatDate
} from '../../../../../../lib/data';
import { SubmitAccountRecoveryRequest } from '../../../../../../lib/components/SubmitAccountRecoveryRequest';

import './styles.scss';
import '../../../styles.scss';
import {
  ConfirmDeactivation,
  UserForDeactivation
} from '../../../../../../lib/components/ConfirmDeactivation';

// eslint-disable-next-line no-empty-pattern
export const Dlocs = ({}: { path?: string }): h.JSX.Element => {
  const { dlocs, refreshDlocs } = useDlocs();
  const [activeDlocs, setActiveDlocs] = useState<dlocType[]>([]);
  const client = useContext(AdminClientContext);
  const [dlocToRecoverAccountFor, setDlocToRecoverAccountFor] = useState<dlocType | undefined>();
  const [dlocToDeactivate, setDlocToDeactivate] = useState<dlocType | undefined>();
  const [userToDeactivate, setUserToDeactivate] = useState<UserForDeactivation | undefined>();
  const [showAccountRecoverySuccess, setShowAccountRecoverySuccess] = useState<boolean>(false);
  const [showAccountRecoveryError, setShowAccountRecoveryError] = useState<boolean>(false);
  const [accountDeactivationError, setAccountDeactivationError] = useState<string>('');
  const [showAccountDeactivationSuccess, setShowAccountDeactivationSuccess] = useState<boolean>(false);

  useEffect(() => {
    setActiveDlocs(dlocs.filter((dloc) => dloc.isActive && dloc.isActivated && !dloc.dateDeactivated ));
  }, [dlocs]);

  const submitRecoveryRequest = async (email: string, phone: string) => {
    let loader: HTMLElement;
    try {
      loader = document.getElementById('loaderWrapper');
      if (loader) {
        loader.style.display = 'flex';
      }
      setDlocToRecoverAccountFor(undefined);
      const { success } = await client.submitAccountRecoveryRequest(email, phone, 'd');
      setShowAccountRecoverySuccess(success);
      setShowAccountRecoveryError(!success);
    } finally {
      if (loader) {
        loader.style.display = 'none';
      }
    }
  };

  const deactivateDloc = async (email: string, phone: string) => {
    let loader: HTMLElement;
    try {
      loader = document.getElementById('loaderWrapper');
      if (loader) {
        loader.style.display = 'flex';
      }
      const dlocId = userToDeactivate.id;
      setUserToDeactivate(undefined);
      await client.deactivateDloc(dlocId, email, phone);
      setShowAccountDeactivationSuccess(true);
    } catch (e) {
      setAccountDeactivationError((e as Error).message);
    } finally {
      if (loader) {
        loader.style.display = 'none';
      }
      refreshDlocs();
    }
  };

  return (
    <Fragment>
      <div id="loaderWrapper">
        <div className="loaderContainer">
          <div className='loader' />
        </div>
      </div>

      <main id='dlocMain'>
        <div id='dlocCard'>
          <h1>All DLOCs</h1>
          <div id='dlocTable'>
            <table>
              <tbody>
                <tr id='dlocTableHeader'>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                {
                  dlocs.map((dloc) => (
                    <tr className='record' key={dloc.id}>
                      <td>{dloc.id}</td>
                      <td>{dloc.name}</td>
                      <td>{dloc.email}</td>
                      {dloc.isActivated && (
                        <Fragment>
                          <td>{dloc.isActive ? 'Active' : 'Inactive'}</td>
                          <td className={dloc.isActive ? 'moreIcon' : ''}>{dloc.isActive && (
                            <Fragment>
                              <img src={require('../../../assets/icons/more.svg') as string} alt='more icon' />
                              <div className='moreDropdown'>
                                <button onClick={() => {
                                  setDlocToRecoverAccountFor(dloc);
                                }}>Recover Account
                                </button>
                                <button onClick={() => {
                                  setDlocToDeactivate(dloc);
                                }}
                                disabled={activeDlocs.length <= 2}>Deactivate Account
                                </button>
                              </div>
                            </Fragment>
                          )}</td>
                        </Fragment>
                      )}
                      {!dloc.dateDeactivated && !dloc.isActivated && dloc.isRegistered && (
                        <Fragment>
                          <td>Needs activation</td>
                          <td></td>
                        </Fragment>
                      )}
                      {!dloc.dateDeactivated && !dloc.isActivated && !dloc.isRegistered && (
                        <Fragment>
                          <td>Not registered</td>
                          <td></td>
                        </Fragment>
                      )}
                      {dloc.dateDeactivated && (
                        <Fragment>
                          <td>Deactivated<br />{formatDate(dloc.dateDeactivated)}</td>
                          <td></td>
                        </Fragment>
                      )}
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </main>

      {dlocToRecoverAccountFor && (
        <SubmitAccountRecoveryRequest
          onCancel={() => {
            setDlocToRecoverAccountFor(undefined);
          }}
          onSubmit={submitRecoveryRequest}
          name={dlocToRecoverAccountFor.name}
          emailAddress={dlocToRecoverAccountFor.email}
        />
      )}

      {showAccountRecoveryError && (
        <Toast
          error={true}
          message="There was an error attempting to send account recovery email. Please try again."
          closeFunc={async () => {
            setShowAccountRecoveryError(false);
            return Promise.resolve();
          }}
        />
      )}

      {showAccountRecoverySuccess && (
        <Toast
          error={false}
          message="Account recovery email sent"
          closeFunc={async () => {
            setShowAccountRecoverySuccess(false);
            return Promise.resolve();
          }}
        />
      )}

      {dlocToDeactivate && (
        <ConfirmDeactivation
          onCancel={() => {
            setDlocToDeactivate(undefined);
          }}
          onConfirm={(user: UserForDeactivation) => {
            setUserToDeactivate(user);
            setDlocToDeactivate(undefined);
          }}
          user={{
            id: dlocToDeactivate.id,
            name: dlocToDeactivate.name,
            emailAddress: dlocToDeactivate.email
          }}
        />
      )}

      {userToDeactivate && (
        <SubmitAccountRecoveryRequest
          onCancel={() => {
            setUserToDeactivate(undefined);
          }}
          onSubmit={deactivateDloc}
          name={userToDeactivate.name}
          emailAddress={userToDeactivate.emailAddress}
        />
      )}

      {accountDeactivationError && (
        <Toast
          error={true}
          message={accountDeactivationError}
          closeFunc={async () => {
            setAccountDeactivationError('');
            return Promise.resolve();
          }}
        />
      )}

      {showAccountDeactivationSuccess && (
        <Toast
          error={false}
          message="Account deactivated"
          closeFunc={async () => {
            setShowAccountDeactivationSuccess(false);
            return Promise.resolve();
          }}
        />
      )}
    </Fragment>
  );
};
