import {
  useContext,
  useEffect,
  useState
} from 'preact/hooks';
import {
  AppState,
  useAuthState
} from '../../../../lib/init/store';
import { AdminClientContext } from './components/AdminClientContext';
import {
  admin,
  dloc
} from '../../../../lib/data';
import {
  useAction,
  useSelector
} from '@preact-hooks/unistore';
import { NewAdminData } from '../../client';

type AdminState = AppState & { admins?: admin[] };
type DlocState = AppState & { dlocs?: dloc[] };

export const useContactInfo = (): {
  contact: {
    username: string;
    name: string;
    email: string;
  };
  setContactInfo: (info: {
    name: string;
    email: string;
  }) => Promise<void>;
}  => {
  const defaultState = {
    name: '',
    email: ''
  };
  const client = useContext(AdminClientContext);
  const { loggedIn } = useAuthState();
  const [profile, setProfile] = useState(defaultState);

  const loadProfile = () => {
    if (loggedIn) {
      void client.getContactInfo().then((result) => {
        setProfile(result);
      });
    } else {
      setProfile(defaultState);
    }
  };

  const setContactInfo = async (info: {
    name: string;
    email: string;
  }) => {
    await client.updateContactInfo(
      info.name,
      info.email
    );
    // We didnt' throw, so we know it's good
    setProfile(info);
  };

  useEffect(loadProfile, [loggedIn]);

  return {
    contact: { ...profile, username: client.username },
    setContactInfo,
  };
};

export const useAdmins = (): {
  admins: admin[];
  refreshAdmins: () => void;
  inviteNewAdmin: (data: NewAdminData) => Promise<boolean>;
  resendInvitation: (adminId: string) => Promise<boolean>;
  activateAdmin: (adminId: string) => Promise<void>;
  isActivated: (adminId: string) => boolean;
} => {
  const { loggedIn } = useAuthState();
  const admins = useSelector((state: AdminState) => state.admins ?? []);
  const client = useContext(AdminClientContext);
  const setAdmins = useAction((state: AdminState, loadedAdmins: AdminState['admins']) => ({
    admins: loadedAdmins.sort((ad1, ad2) => {
      if (ad1.name < ad2.name) {
        return -1;
      }

      if (ad2.name < ad1.name) {
        return 1;
      }

      return 0;
    })
  }));

  const refreshAdmins = () => {
    if (loggedIn) {
      void client.getAllAdmins().then((loadedAdmins) => {
        setAdmins(loadedAdmins);
      });
    } else {
      setAdmins([]);
    }
  };

  const inviteNewAdmin = async (data: NewAdminData) =>
    client.inviteNewAdmin(data);

  const resendInvitation = async (adminId: string) =>
    client.inviteNewAdmin(null, adminId);

  const activateAdmin = async (adminId: string): Promise<void> =>
    client.activateNewAdmin(adminId);

  const isActivated = (adminId: string): boolean => {
    const identifiedAdmin = admins.filter((ad) => ad.id === adminId)[0];
    return identifiedAdmin && identifiedAdmin.isActivated;
  };

  useEffect(() => {
    refreshAdmins();
  }, [loggedIn]);

  return {
    admins,
    refreshAdmins,
    inviteNewAdmin,
    resendInvitation,
    activateAdmin,
    isActivated
  };
};

export const useDlocs = (): {
  dlocs: dloc[];
  refreshDlocs: () => void;
} => {
  const { loggedIn } = useAuthState();
  const client = useContext(AdminClientContext);
  const dlocs = useSelector((state: DlocState) => state.dlocs ?? []);
  const setDlocs = useAction((state: DlocState, loadedDlocs: DlocState['dlocs']) => ({
    dlocs: loadedDlocs.sort((dloc1, dloc2) => {
      if (dloc1.name < dloc2.name) {
        return -1;
      }

      if (dloc1.name > dloc2.name) {
        return 1;
      }

      return 0;
    })
  }));

  const refreshDlocs = () => {
    if (loggedIn) {
      void client.getAllDlocs().then((loadedDlocs) => {
        setDlocs(loadedDlocs);
      });
    } else {
      setDlocs([]);
    }
  };

  useEffect(() => {
    refreshDlocs();
  }, [loggedIn]);

  return {
    dlocs,
    refreshDlocs
  };
};
